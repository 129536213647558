/* eslint-disable camelcase */
;(function($) {
  // contact_init()

  $('[data-fancy="fancybox"]').fancybox({
    width: 600,
    height: 400
  })

  $('.ditect_btn').click(function(e) {
    e.preventDefault()

    // view
    $('.ditect_btn').removeClass('active')
    $(this).addClass('active')

    // data
    var data_latEnd = $('.ditect_btn.active').attr('data-lat')
    var data_lngEnd = $('.ditect_btn.active').attr('data-lng')
    var url = 'https://www.google.com/maps/dir/?api=1'
    var destination = '&destination=' + data_latEnd + ',' + data_lngEnd
    var newUrl = new URL(url + destination)

    // ctrl
    window.open(newUrl, '_blank').focus
  })
})(jQuery)
